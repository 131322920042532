<template>
  <div class="api">
    <div class="container space-lg-1 pl-0">
      <div aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'home', params: { lang: $route.params.lang }}">{{ $t('CC01-03-08') }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ $t('M01b-03') }}</a></li>
        </ol>
      </div>
    </div>
    <div id="scroll-to-div" class="container">
      <div id="stickyBlockStartPoint" />
      <div class="container space-2">
        <div class="row justify-content-lg-between">
          <div class="col-md-6 col-lg-5">
            <div class="mb-5">
              <h2>{{ $t('M01b-03') }}</h2>
              <p>{{ $t('M01b-04') }}</p>
            </div>
          </div>
          <div class="col-md-6 d-none d-md-inline-block">
            <figure class="w-100">
              <img class="img-fluid" :src="require(`@/assets/svg/illustrations/community.svg`)" alt="Image Description">
            </figure>
          </div>
        </div>
      </div>
      <div v-if="events" class="container space-top-md-2 space-bottom-2 space-bottom-lg-3" data-aos="fade-up">
        <input v-for="item in category_list" :id="item.category" :key="item.category" v-model="selectedCategory" type="radio" name="categories" :value="item.category">
        <ol class="filters-btn">
          <li v-for="item in category_list" :key="item.category">
            <label class="btn btn-xs btn-soft-secondary btn-pill py-2 px-3 m-1 category-filter" :class="{'selectedCategory': selectedCategory === item.category}" :for="item.category">{{ item.category_title }}</label>
          </li>
        </ol>
        <ol class="row">
          <!-- Filter Card Item -->
          <li v-for="(item, index) in latest_events" :key="index" class="col-md-6 col-lg-4 mb-5 filters-item" :data-category="item.category">
            <EventCard :item="item" />
          </li>
        </ol>
      </div>
      <div id="stickyBlockEndPoint" />
    </div>
  </div>
</template>

<script>
import EventCard from '@/components/event-card'
import { mapGetters } from 'vuex'
import { formatName } from '@/utils/format-util'
import moment from 'moment'

export default {
  name: 'CommunityUpdates',
  components: {
    EventCard
  },
  data() {
    return {
      category_list: [],
      selectedCategory: 'CC06-01-01',
      perfer_lang: 'en-us'
    }
  },
  computed: {
    ...mapGetters([
      'events'
    ]),
    events_with_prefer_lang() {
      //  Do this for now
      return this.events.filter(event => event.lang_code === this.perfer_lang)
    },
    latest_events() {
      if (this.events_with_prefer_lang.length === 0) return []
      else {
        const events = this.sortEvents()
        if (this.selectedCategory === 'CC06-01-01') {
          return events
        } else {
          const category = this.selectedCategory
          const filteredEvents = events.filter(event => formatName(event.category) === category)
          return filteredEvents
        }
      }
    }
  },
  watch: {
    async events() {
      if (this.events.length === 0) {
        await this.$store.dispatch('events/getEventList')
      }
      this.getCategoryList(this.events_with_prefer_lang)
    }
  },
  async created() {
    console.log('events', this.events)
    if (this.events.length === 0) {
      await this.$store.dispatch('events/getEventList')
    }
    this.getCategoryList(this.events_with_prefer_lang)
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Community Updates | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/community-updates' }
      ],
      meta: [
        { name: 'description', content: 'tay up to date with our API community activities & partner offerings.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Community Updates | OpenAPIHub' },
        { property: 'og:description', content: 'tay up to date with our API community activities & partner offerings.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/community-updates' }
      ]
    }
  },
  methods: {
    sortEvents() {
      return this.events_with_prefer_lang.slice().sort((a, b) => {
        var aTime = a.compareTime ? moment(a.compareTime) : (a.startTime ? moment(a.startTime) : moment(a.date))
        var bTime = b.compareTime ? moment(b.compareTime) : (b.startTime ? moment(b.startTime) : moment(b.date))
        if (aTime > bTime) {
          return -1
        } else if (bTime > aTime) {
          return 1
        } else return 0
      })
    },
    getCategory(event_list) {
      var array = []
      var temp = []
      for (var i = 0; i < event_list.Events.length; i++) {
        array = array.concat(event_list.Events[i].category)
      }
      temp = Array.from(new Set(array))
      temp.sort()
      this.category_list = [this.$t('CC06-01-01')]
      this.category_list = this.category_list.concat(temp)
    },
    getCategoryList(list) {
      function compare(a, b) {
      // Use toUpperCase() to ignore character casing
        const titleA = a.category_title.toUpperCase()
        const titleB = b.category_title.toUpperCase()

        let comparison = 0
        if (titleA > titleB) {
          comparison = 1
        } else if (titleA < titleB) {
          comparison = -1
        }
        return comparison
      }
      let array = []
      const temp = []
      for (let i = 0; i < list.length; i++) {
        const object = {
          category_title: '',
          category: ''
        }
        object.category_title = this.$t(list[i].category)
        object.category = formatName(list[i].category)
        array = array.concat(object)
      }
      const map = new Map()
      // eslint-disable-next-line no-unused-vars
      for (const item of array) {
        if (!map.has(item.category_title)) {
          map.set(item.category_title, true)
          temp.push({
            category_title: item.category_title,
            category: item.category
          })
        }
      }
      temp.sort(compare)
      this.category_list = [{ category_title: this.$t('CC06-01-01'), category: 'CC06-01-01' }]
      this.category_list = this.category_list.concat(temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedCategory, .category-filter:hover {
  background: #1c344c !important;
  color: #fff !important;
}
</style>
