<template>
  <a class="card h-100 cursor-pointer" @click="redirect(item)">
    <div class="card-img-top position-relative">
      <img class="card-img-top" :src="`/assets/events/${item.image_url}`">
      <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="d-block small font-weight-bold text-cap">{{ $t(item.category) }}</span>
        <span v-if="eventStatus" class="badge" :class="eventStatus | badgeColor">{{ $t(eventStatus.label) }}</span>
      </div>
      <div class="mb-5">
        <h4>{{ item.title }}</h4>
        <p v-if="item.description" class="font-size-1">{{ item.description }}</p>
      </div>
      <div v-if="item.startTime || item.date" class="media text-body font-size-1 mb-2">
        <div class="min-w-3rem text-center mr-3">
          <i class="far fa-calendar-alt" />
        </div>
        <div class="media-body">{{ eventDate }}</div>
      </div>
      <div v-if="item.startTime" class="media text-body font-size-1 mb-2">
        <div class="min-w-3rem text-center mr-3">
          <i class="far fa-clock" />
        </div>
        <div class="media-body">{{ eventTime }} ({{ item.timezone ? item.timezone : 'HKT' }})</div>
      </div>
    </div>
    <div class="card-footer border-0 pt-0">
      <span class="font-weight-bold">{{ $t('CC08-08') }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
    </div>
  </a>
</template>

<script>
import { getDate, getTime, getYYYYMMDD } from '@/utils/date-time-util'
import { formatName, getEventStatus } from '@/utils/format-util'

export default {
  name: 'EventCard',
  filters: {
    badgeColor: (status) => {
      if (!status) return ''
      else {
        var badgeClass = ''
        switch (status.value) {
          case 'upcoming': badgeClass = 'badge-soft-primary'
            break
          case 'in progress': badgeClass = 'badge-soft-warning'
            break
          case 'ended': badgeClass = 'badge-soft-secondary'
            break
          default: badgeClass = ''
            break
        }
        return badgeClass
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          image_url: '',
          category: '',
          title: '',
          description: '',
          date: '',
          time: '',
          external_url: ''
        }
      }
    }
  },
  computed: {
    eventDate() {
      return this.item.startTime ? getDate(this.item.startTime, this.item.endTime) : this.item.date
    },
    eventTime() {
      return getTime(this.item.startTime, this.item.endTime)
    },
    eventPath() {
      var formattedName = this.item.title_eng ? formatName(this.item.title_eng) : formatName(this.item.title)
      var formattedStartDate = ''
      if (this.item.startTime && getYYYYMMDD(this.item.startTime)) {
        formattedStartDate = getYYYYMMDD(this.item.startTime)
      } else if (this.item.compareTime && getYYYYMMDD(this.item.compareTime)) {
        formattedStartDate = getYYYYMMDD(this.item.compareTime)
      } else {
        formattedStartDate = getYYYYMMDD(this.item.date)
      }
      var path = formattedStartDate ? `${formattedStartDate}-${formattedName}` : `${formattedName}`
      return path[path.length - 1] === '-' ? path.substr(0, path.length - 1) : path
    },
    eventStatus() {
      return getEventStatus(this.item)
    }
  },
  methods: {
    redirect(item) {
      if (item.external_url) {
        window.open(item.external_url, '_blank')
      } else {
        this.$router.push({
          name: 'community-updates-details',
          params: {
            eventTitle: this.eventPath,
            lang: this.$route.params.lang
          }
        })
      }
    }
  }
}
</script>
